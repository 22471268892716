import { useState } from 'react';
import styled from '@grebban/style-system-react';
import { above } from '@sportson/core-web/utils/mediaqueries';

import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import Text from 'components/Text';
import Button from 'components/Button';
import { ProductCard, type ProductCardProduct } from 'components/product/ProductCard';
import { type WordpressACFWysiwyg } from 'libs/wordpress/types/acf/fields';
import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';
import { type ButtonProps } from 'components/Button';
import { ProductContainerToAlgoliaProductChildren } from './ProductContainerToAlgoliaProductChildren';
import { CategoryBar } from './CategoryBar';

const Div = styled('div')``;

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
`;

const ContentWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const CardGrid = styled('div')`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

    ${above['desktop.sm']} {
        grid-template-columns: repeat(4, 1fr);
    }
`;

export interface ProductsPromotionComponentPropsHeading {
    text: WordpressACFWysiwyg;
    type?: 'default' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    color?: CssVariable<CssColorKey>;
}

export interface ProductsPromotionParagraphComponentProps {
    text: WordpressACFWysiwyg;
    color?: CssVariable<CssColorKey>;
}

export type ColumnsSectionProps = {
    label: string;
    algoliaProductSelection: {
        default: string[][];
        selected: string[][];
        dynamic: string[][];
    }; // @TODO Should be owned by Algolia/ProductsContainer?
};

export interface PromotionColumnsComponentProps extends Record<string, unknown> {
    heading?: ProductsPromotionComponentPropsHeading;
    buttons?: ButtonProps[];
    paragraph?: ProductsPromotionParagraphComponentProps;
    sections: ColumnsSectionProps[];
    displayLabelsBar: boolean;
}

export const PromotionColumnsComponent = ({
    heading,
    buttons,
    paragraph,
    sections,
    displayLabelsBar,
}: PromotionColumnsComponentProps) => {
    const [activeSection, setActiveSection] = useState<ColumnsSectionProps>(sections[0]);
    return (
        <Wrapper gap={['24px', null, '32px']}>
            <ContentWrapper
                flexDirection={['', null, null, null, 'row']}
                justifyContent={['unset', null, null, null, 'space-between']}
            >
                {heading && (
                    <Wysiwyg
                        data={heading?.text}
                        TextComponent={(props) => (
                            <Text
                                width={['100%', null, null, null, '50%']}
                                maxWidth={['100%', null, null, null, '50%']}
                                textAlign="left"
                                as={heading?.type || 'h3'}
                                color={heading?.color || 'var(--text-color-default)'}
                                typography={['Content/32_130_0_500', null, null, null, 'Content/40_120_0_500']}
                                {...props}
                            />
                        )}
                    />
                )}
                {paragraph && (
                    <Wysiwyg
                        data={paragraph?.text}
                        TextComponent={(props) => (
                            <Text
                                width={['100%', null, null, null, '50%']}
                                textAlign="left"
                                $as="p"
                                color={paragraph?.color || 'var(--text-color-default)'}
                                typography={['Content/16_150_0_350', null, null, null, 'Content/20_140_0_350']}
                                {...props}
                            />
                        )}
                    />
                )}
            </ContentWrapper>

            <ContentWrapper flexDirection={['', null, 'row-reverse']} justifyContent={['unset', null, 'space-between']}>
                {buttons && (
                    <Div display="flex" flexDirection="row" justifyContent="flex-start" gap="16px">
                        {buttons.map((button) => (
                            <Button
                                key={`${button.url}`}
                                typography="UI/16_100_0_450"
                                theme={button.theme || 'primary'}
                                to={button.url}
                            >
                                {button.children}
                            </Button>
                        ))}
                    </Div>
                )}
                {displayLabelsBar && (
                    <CategoryBar
                        sections={sections.map((section) => ({
                            label: section.label,
                            active: section.label === activeSection.label,
                            callback: () => setActiveSection(section),
                        }))}
                    />
                )}
            </ContentWrapper>

            <ProductContainerToAlgoliaProductChildren
                productSelection={activeSection.algoliaProductSelection}
                productsPerPage={8}
            >
                {({ products }) =>
                    products && (
                        <CardGrid gap={['12px', null, '16px', null, '24px']}>
                            {products.map((product: ProductCardProduct) => (
                                <ProductCard key={product.productId} product={product} />
                            ))}
                        </CardGrid>
                    )
                }
            </ProductContainerToAlgoliaProductChildren>
        </Wrapper>
    );
};
